import React from "react"
import "devicon"
import { motion } from "framer-motion"

interface Props {
  icon: string
  title: string
  status: string
  percent?: number
  variants?: any
  custom?: number
  elementVisible: boolean
  color: string
}

const Skill = (props: Props) => {
  // Animation staggered animation
  const variants = {
    visible: i => ({
      width: `${props.percent}%`,
      transition: {
        type: "spring",
        damping: 10,
        delay: i * 0.2,
      },
    }),
    hidden: { width: 0 },
  }

  return (
    <div className="progress">
      <div className="progress__title-container">
        <i
          className={`devicon-${props.icon}-plain`}
          style={{ color: props.color }}
        />
        <span className="progress__title" style={{ color: props.color }}>
          {props.title}
        </span>
      </div>
      <motion.span
        className="progress__status"
        variants={variants}
        custom={props.custom}
        initial={"hidden"}
        animate={props.elementVisible ? "visible" : "hidden"}
        style={{ color: props.color }}>
        {props.status}
      </motion.span>
      <div className="progress__bar">
        <motion.div
          className="progress__value"
          variants={variants}
          custom={props.custom}
          initial={"hidden"}
          animate={props.elementVisible ? "visible" : "hidden"}
          style={{
            backgroundColor: props.color,
            boxShadow: `5px 5px 0px rgba(var(--color-2-rgb), 0.3)}`,
          }}
        />
      </div>
    </div>
  )
}

export default Skill
