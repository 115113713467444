import React, { useCallback } from "react"
import { motion } from "framer-motion"
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPalette,
  faFileCode,
  faDesktop,
  faTasks,
} from "@fortawesome/free-solid-svg-icons"

interface Props {
  title: string
  color: string
  icon: string
  index: number
  setSelectedTab: (index: number) => void
  isActive: boolean
}

const TabTitle = ({
  title,
  color,
  icon,
  isActive,
  setSelectedTab,
  index,
}: Props) => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  const iconType = (icon: string) => {
    switch (icon) {
      case "Desktop":
        return faDesktop
      case "FileCode":
        return faFileCode
      case "Palette":
        return faPalette
      case "Steps":
        return faTasks
      default:
        return faDesktop
    }
  }

  const item = {
    visible: {
      scale: 1,
    },
    hidden: {
      scale: 0.9,
    },
  }

  return (
    <motion.div
      className={isActive ? "prestation prestation--active" : "prestation"}
      animate={isActive ? "visible" : "hidden"}
      variants={item}
      custom={index}
      onClick={onClick}>
      <div className="prestation__icon-container">
        <FontAwesomeIcon
          className="prestation__icon"
          style={{ color: color }}
          icon={iconType(icon)}
        />
        <h5 className="prestation__title" style={{ color: color }}>
          {title}
        </h5>
      </div>
    </motion.div>
  )
}

export default TabTitle
