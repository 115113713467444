import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Home Components
import Hero from "../components/Home/Hero"
import Introduction from "../components/Home/Introduction"
import Skills from "../components/Home/Skills/Skills"
import Services from "../components/Home/Prestations/Services"
import Clients from "../components/Home/Clients/Clients"
import Info from "../components/Home/Info"

const index = () => {
  return (
    <Layout headerType="transparent--light" flexGrow={false}>
      <SEO title="Home" />
      <Hero />
      <Introduction />
      <Skills />
      <Services />
      <Clients />
      <Info />
    </Layout>
  )
}

export default index
