import React from "react"

interface Props {
  children: React.ReactNode
  style?: any
}

// Initial Editor Component
const Editor = (props: Props) => {
  return (
    <div className="window" style={props.style}>
      <div className="window__header">
        <div className="window__dots">
          <div className="window__dot"></div>
          <div className="window__dot"></div>
          <div className="window__dot"></div>
        </div>
      </div>
      <div className="window__content">{props.children}</div>
    </div>
  )
}

export default Editor
