import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"

import Title from "../Layout/Title"
import Atom from "../Motion/Atom"

const Introduction = () => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <section id="Introduction">
      <div className="section__container">
        <div className="section__columns">
          <div className="section__column">
            <Title
              upper="Développeur Front"
              lower="Mais pas que..."
              highlight={false}
            />
            <InView
              as="div"
              threshold={0.8}
              triggerOnce={true}
              onChange={inView => setIsVisible(inView)}>
              <motion.div
                animate={
                  isVisible
                    ? { opacity: 1, translateY: 0 }
                    : { opacity: 0, translateY: 30 }
                }
                transition={{ delay: 0, type: "spring" }}>
                <p>
                  Je suis Olivier, développeur d'interfaces polyvalent armé
                  d'une sensibilité en UX et UI Design ayant pour aspiration
                  d'allier technologie, performances et esthétisme au de sein
                  votre projet.
                </p>
                <p>
                  Après 3 ans de formations et plus 2 ans d'expérience, je
                  m'affirme en tant que <strong>Développeur Front-End</strong>{" "}
                  doté d'une approche assurément créative.
                </p>
                <p>
                  Actuellement en Free-lance basé sur <strong>Paris</strong> et
                  je serais ravi de pouvoir échanger avec vous sur votre projet.
                  Je reste également ouvert à d'éventuelles nouvelles
                  propositions.
                </p>
              </motion.div>
            </InView>
          </div>
          <motion.div
            className="section__column"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            animate={
              isVisible
                ? { opacity: 1, rotate: 0, scale: 1 }
                : { opacity: 0, rotate: 200, scale: 3 }
            }
            transition={{ delay: 0, type: "spring" }}>
            <Atom />
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Introduction
