import React from "react"
import { Link } from "gatsby"

import Button from "../Button"

const Info = () => {
  return (
    <section id="Dialog">
      <div className="section__container">
        <div className="dialog">
          <div className="section__left">
            <h3>Mon profil vous intéresse ?</h3>
            <p>
              Contactez-moi via le formulaire de contact et retrouvez-moi sur
              les réseaux ci-dessous.
            </p>
          </div>
          <div className="section__left">
            <Link to="/contact">
              <Button color="light" type="button">
                Contactez-moi
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Info
