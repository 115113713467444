import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import ProjectItem from "./ProjectItem"
import Title from "../../Layout/Title"

// Simple Projects components viewer
const Clients = () => {
  const data = useStaticQuery(graphql`
    query Projects {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/work/" } }
        limit: 3
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              tags
              mission
              type
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <section id="Clients">
      <div className="section__container">
        <Title upper="Quelques" lower="Projets" />
        <div className="projects--row">
          {data.allMarkdownRemark.edges.map((project: any, index: number) => (
            <ProjectItem
              link={`/work${project.node.fields.slug}`}
              title={project.node.frontmatter.title}
              description={project.node.frontmatter.description}
              img={project.node.frontmatter.thumbnail.childImageSharp.fluid}
              type={project.node.frontmatter.type}
              custom={index}
              key={index}
            />
          ))}
        </div>
        <p style={{ opacity: 0.5 }}>
          * Découvrez l'ensemble des projets en cliquant sur ce{" "}
          <Link to="/work">lien</Link>
        </p>
      </div>
    </section>
  )
}

export default Clients
