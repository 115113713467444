import React, { useState } from "react"
import TabTitle from "./TabTitle"

interface Props {
  children: React.ReactNodeArray
}

const Tabs = (props: Props) => {
  // Default selectedTab index is 0
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div className="columns--2">
      <div className="column">
        <div className="prestations">
          {props.children.map((item: any, index: number) => (
            <TabTitle
              isActive={selectedTab === index ? true : false}
              key={index}
              title={item.props.title}
              color={item.props.color}
              icon={item.props.icon}
              index={index}
              setSelectedTab={setSelectedTab}
            />
          ))}
        </div>
      </div>
      <div className="column">{props.children[selectedTab]}</div>
    </div>
  )
}

export default Tabs
