import React from "react"
import { motion } from "framer-motion"
interface Props {
  title: string
  children: React.ReactNode
}

const Tab = (props: Props) => {
  return (
    <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }}>
      {props.children}
    </motion.div>
  )
}

export default Tab
