import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"

import Title from "../../Layout/Title"
import Skill from "./Skill"

const Skills = () => {
  const skillsList = [
    {
      title: "React.JS / Native",
      icon: "react",
      status: "3 ans expérience",
      percent: 60,
      color: "var(--color-2)",
    },
    {
      title: "Sass & Modern CSS",
      icon: "sass",
      status: "4 ans d'expérience",
      percent: 70,
      color: "var(--color-3)",
    },
    {
      title: "HTML (sémentique)",
      icon: "html5",
      status: "5 ans d'expérience",
      percent: 80,
      color: "var(--color-4)",
    },
    {
      title: "Typescript / Javascript",
      icon: "typescript",
      status: "3 ans d'expérience",
      percent: 60,
      color: "var(--color-5)",
    },
  ]

  const [isVisible, setIsVisible] = useState(false)

  return (
    <section id="Benchmark">
      <div className="section__container">
        <Title upper="Stack" lower="Technique" />
        <div className="section__columns">
          <InView
            as="div"
            className="section__column"
            threshold={0.8}
            triggerOnce={true}
            onChange={inView => setIsVisible(inView)}>
            {skillsList.map((skill: any, index: number) => (
              <Skill
                key={index}
                icon={skill.icon}
                title={skill.title}
                status={skill.status}
                percent={skill.percent}
                custom={index}
                elementVisible={isVisible}
                color={skill.color}
              />
            ))}
          </InView>
          <div
            className="section__column"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}>
            <div className="section__row">
              <p>
                A l’affût constant des nouvelles tendances, je maintiens
                régulièrement à jour mes compétences suivant le rythme des
                évolutions technologiques afin de proposer la solution la plus
                adaptée dans l'air du temps selon, la nature de votre projet.
              </p>
              <p>
                Polyvalent, je saurai également m'adapter à votre stack
                existante.
              </p>
            </div>
            <div className="section__row">
              <p>Je travaille aussi avec :</p>
              <div className="badges__columns">
                <motion.div className="badge badge--dark">Redux</motion.div>
                <motion.div className="badge badge--dark">
                  Bash / Linux
                </motion.div>
                <motion.div className="badge badge--dark">
                  GatsbyJS / Next.js
                </motion.div>
                <motion.div className="badge badge--dark">GraphQL</motion.div>
                <motion.div className="badge badge--dark">NodeJS</motion.div>
                <motion.div className="badge badge--dark">Jamstack</motion.div>
                <motion.div className="badge badge--dark">BEM CSS</motion.div>
                <motion.div className="badge badge--dark">
                  Figma / Sketch / Adobe XD
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Skills
