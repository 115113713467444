import React from "react"

// Hero Moving Blobs
import HeroLeft from "../Motion/Hero/HeroLeft"
import HeroMiddle from "../Motion/Hero/HeroMiddle"
import HeroRight from "../Motion/Hero/HeroRight"

const Hero = () => (
  <div className="hero">
    <div className="hero__content">
      <div className="hero__body">
        <span>Concrétisez vos idées et sublimez votre projet</span>
        <h1>
          <strong>Front-End</strong> Developper
          <br />
          <strong>& UX / UI</strong> Designer
        </h1>
      </div>
    </div>
    <HeroLeft />
    <HeroMiddle />
    <HeroRight />
  </div>
)

export default Hero
