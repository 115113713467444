import React from "react"

// Custom Components
import Title from "../../Layout/Title"
import Tab from "./Tab"
import Tabs from "./Tabs"
import Editor from "../../Motion/Editor"

const Services = () => {
  return (
    <section className="section__container">
      <Title upper="Expertise &" lower="Services" />
      <p style={{ opacity: 0.5 }}>
        * Cliquez sur les titres correspondant pour en savoir plus.
      </p>
      <Tabs>
        <Tab
          title="Développement Front-End"
          icon="Desktop"
          color="var(--color-2)">
          <Editor style={{ backgroundColor: "var(--color-2)", color: "#fff" }}>
            <h3>Développement Front-End</h3>
            <blockquote>
              <p>
                Développez votre produit digital tout supports et apportez une
                experience utilsateur engageante et interactive.
              </p>
            </blockquote>
            <p>
              Seul ou en équipe, je travaille essentiellement avec React.JS sur
              les projets d'application web, PWA (Progressive Web App) ou
              mobile.
            </p>
            <p>
              Spécialisé autour de l'écosystème Javascript, je tâche d'exploiter
              au mieux le potentiel de cette technologie.
            </p>
            <p>
              J'accorde une importance particulière à l'ergonomie, les
              performances ainsi que la fluidité.
            </p>
          </Editor>
        </Tab>
        <Tab title="Intégration Web" icon="FileCode" color="var(--color-3)">
          <Editor style={{ backgroundColor: "var(--color-3)", color: "#fff" }}>
            <h3>Intégration Web</h3>
            <blockquote>
              <p>Donnez vie à vos maquettes graphiques !</p>
            </blockquote>
            <p>
              Intégration sémantique et responsive de vos maquettes graphiques
              en HTML / CSS / JS dans les respects des normes du W3C ainsi que
              celles de compatibilité avec les navigateurs actuels.
            </p>
            <p>
              Les livrables se présentent sous la forme de fichiers HTML, CSS et
              JS - SCSS.
            </p>
          </Editor>
        </Tab>
        <Tab title="UX / UI Design" icon="Palette" color="var(--color-4)">
          <Editor
            style={{
              backgroundColor: "var(--color-4)",
              color: "#fff",
            }}>
            <h3>UX / UI Design</h3>
            <blockquote>
              <p>
                Optimisez l'expérience utilisateur de votre site internet ou de
                votre application.
              </p>
            </blockquote>
            <p>
              Allant de l'étude d'utilisateurs jusqu’à l'optimisation des
              performances de parcours et interfaces, je réalise vos maquettes
              web dans le respect de l'identité visuelle de votre marque.
            </p>
            <small>
              <p>
                * UX: ( User Experience ) Conception de l'expérience utilisateur
                visant à apporter une réflexion sur la facilité d’usage d’une
                interface.
              </p>
              <p>
                * UI : ( User Interface ) Conception de l'interface utilisateur
                ainsi que l'apparence graphique.
              </p>
            </small>
          </Editor>
        </Tab>
        <Tab title="From Scratch *" icon="Steps" color="var(--color-5)">
          <Editor
            style={{
              backgroundColor: "var(--color-5)",
              color: "#000",
            }}>
            <h3>From scratch *</h3>
            <p>
              D'une simple idée jusqu'à la mise en ligne de votre projet web, je
              propose de vous accompagner étape par étape tout au long du
              processus de développement et faites de votre site internet une
              pièce unique !
            </p>
            <ul>
              <li>Reflexion et Recherches</li>
              <li>UX / UI Design</li>
              <li>Développment et intégration</li>
              <li>Testes</li>
              <li>Livraison / Hebergement</li>
              <li>Maintenance</li>
            </ul>
            <p>
              Le temps de réalisation et de livraison est variable selon les
              dimensions de votre projet. Pour plus d'informations, n'hesitez
              pas à me contacter.
            </p>
            <p>
              <small>
                From Scratch* : Réalisation de votre projet web sur mesure en
                partant de zéro
              </small>
            </p>
          </Editor>
        </Tab>
      </Tabs>
    </section>
  )
}

export default Services
